<script>
  import { ja as localeJa } from "date-fns/locale";
  import { escape } from "html-escaper";
  import L from "leaflet";
  import sleep from "sleep-promise";
  import { onMount, tick } from "svelte";
  import { _ } from "svelte-i18n";

  import deliveredIconImage from "~/assets/images/leaflet/delivered-27x42.png";
  import depotIconImage from "~/assets/images/leaflet/depot-39x31.png";
  import driverIconImage from "~/assets/images/leaflet/driver_40x27.png";
  import locationIconImage from "~/assets/images/leaflet/location-26x41.png";
  import undeliverableIconImage from "~/assets/images/leaflet/undeliverable-27x42.png";
  import { DeliveryRecordTypes, driverConditionType } from "~/libs/constants";
  import { formatStringDate, formatTrackingNumber } from "~/libs/utils";
  import { getCenterNameAndPackageNumString } from "~/pages/Activity/activityUtils";

  import "leaflet/dist/leaflet.css"; // LeafletのCSSを読込

  /**
   * 選択された検索条件を格納する変数
   * - 0: 幹線輸送ドライバー
   * - 1: 宅配ドライバー
   * @type {import("~/libs/constants").driverConditionType}
   */
  export let selectConditions;
  $: changeLayer(selectConditions);

  /**
   * @type {Array<import("~/libs/commonTypes").DriverActivity>}
   */
  export let courierResults;

  /**
   * @type {Array<import("~/libs/commonTypes").DriverActivity>}
   */
  export let truckerResults;

  /** @type {number} 現在選択中のドライバーIndex */
  export let currentDriverIndex;
  $: moveToCurrentDriver(currentDriverIndex);

  /** @type {Array<number>} 関連する配送センターIDを格納する配列 */
  export let centerIdList;

  /** @type {Map<number, object>} センターIDをキーとしたセンター情報のマップ*/
  export let centersMap;

  /** @enum {0 | 1 | 2} */
  const PackageTypes = Object.freeze({
    /** 未配達 */
    UNDELIVERED: 0,
    /** 配達済み */
    DELIVERED: 1,
    /** 配達不可 */
    UNDELIVERABLE: 2,
  });

  /** @type {L.Map} */
  let map;

  /** @type {Map<number, L.Marker>} 宅配ドライバーマーカーのマップ */
  let courierMarkerMap = new Map();
  /** @type {Map<number, L.Marker>} 宅配ドライバーマーカーのマップ(配送記録表示モード用) */
  let courierMarkerMapForRecordsMode = new Map();
  /** @type {L.LayerGroup} 宅配ドライバーマーカーのレイヤーグループ */
  let courierMarkerGroup;

  /** @type {Map<number, L.Marker>} 幹線輸送ドライバーマーカーのマップ */
  let truckerMarkerMap = new Map();
  /** @type {L.LayerGroup} 幹線輸送ドライバーマーカーのレイヤーグループ */
  let truckerMarkerGroup;

  /** @type {Map<number, L.LayerGroup>} ドライバー配送記録のレイヤーグループのマップ */
  let deliveryRecordsLayerMap = new Map();

  /** @type {import("~/libs/commonTypes").DriverActivity} */
  let currentDriver;

  /** @type {boolean} 配送記録表示モードかどうか */
  let deliveryRecordsMode = false;

  /** 未配達荷物用のマーカーアイコン */
  // @ts-ignore
  const LocationIcon = L.Icon.extend({
    options: {
      iconUrl: locationIconImage,
      iconSize: [26, 41],
      iconAnchor: [13, 36],
      popupAnchor: [0, -40],
      tooltipAnchor: [16, -28],
      className: "leaflet-location-icon",
    },

    createIcon: function () {
      const wrapperDiv = document.createElement("div");
      const numberDiv = document.createElement("div");
      const iconImg = this._createImg(this.options["iconUrl"]);
      numberDiv.setAttribute("class", "number");
      numberDiv.textContent = this.options["number"] ?? "";
      wrapperDiv.appendChild(iconImg);
      wrapperDiv.appendChild(numberDiv);
      this._setIconStyles(wrapperDiv, "icon");
      return wrapperDiv;
    },

    createShadow: function () {
      return null;
    },
  });

  /** 配達完了荷物用のマーカーアイコン */
  // @ts-ignore
  const DeliveredIcon = L.Icon.extend({
    options: {
      iconUrl: deliveredIconImage,
      iconSize: [26, 41],
      iconAnchor: [13, 36],
      popupAnchor: [0, -40],
      tooltipAnchor: [16, -28],
      className: "leaflet-location-icon",
    },

    createIcon: function () {
      const wrapperDiv = document.createElement("div");
      const numberDiv = document.createElement("div");
      const iconImg = this._createImg(this.options["iconUrl"]);
      numberDiv.setAttribute("class", "number");
      numberDiv.textContent = this.options["number"] ?? "";
      wrapperDiv.appendChild(iconImg);
      wrapperDiv.appendChild(numberDiv);
      this._setIconStyles(wrapperDiv, "icon");
      return wrapperDiv;
    },

    createShadow: function () {
      return null;
    },
  });

  /** 配達不可荷物用のマーカーアイコン */
  // @ts-ignore
  const UndeliverableIcon = L.Icon.extend({
    options: {
      iconUrl: undeliverableIconImage,
      iconSize: [26, 41],
      iconAnchor: [13, 36],
      popupAnchor: [0, -40],
      tooltipAnchor: [16, -28],
      className: "leaflet-location-icon",
    },

    createIcon: function () {
      const wrapperDiv = document.createElement("div");
      const numberDiv = document.createElement("div");
      const iconImg = this._createImg(this.options["iconUrl"]);
      numberDiv.setAttribute("class", "number");
      numberDiv.textContent = this.options["number"] ?? "";
      wrapperDiv.appendChild(iconImg);
      wrapperDiv.appendChild(numberDiv);
      this._setIconStyles(wrapperDiv, "icon");
      return wrapperDiv;
    },

    createShadow: function () {
      return null;
    },
  });

  /** ドライバー用のマーカーアイコン */
  // @ts-ignore
  const DriverIcon = L.Icon.extend({
    options: {
      iconUrl: driverIconImage,
      iconSize: [40, 27],
      iconAnchor: [17, 18],
      popupAnchor: [0, -23],
      tooltipAnchor: [16, -28],
      className: "leaflet-driver-icon",
    },

    createIcon: function () {
      const wrapperDiv = document.createElement("div");
      const numberDiv = document.createElement("div");
      const iconImg = this._createImg(this.options["iconUrl"]);
      numberDiv.setAttribute("class", "number");
      numberDiv.textContent = this.options["number"] ?? "";
      wrapperDiv.appendChild(iconImg);
      wrapperDiv.appendChild(numberDiv);
      this._setIconStyles(wrapperDiv, "icon");
      return wrapperDiv;
    },

    createShadow: function () {
      return null;
    },
  });

  /** 配送センター用のマーカーアイコン */
  const depotIcon = L.icon({
    iconUrl: depotIconImage,
    iconSize: [39, 31],
    iconAnchor: [20, 16],
    popupAnchor: [0, -20],
    tooltipAnchor: [16, -28],
    className: "leaflet-depot-icon",
  });

  onMount(async () => {
    await tick();

    // 初期状態は宅配ドライバーの1件目を中心に地図を表示
    currentDriver = courierResults[0];

    // 選択中のドライバーを中心としたZoomレベル12の地図を作成
    map = L.map("routeMap", { closePopupOnClick: false }).setView(
      [
        currentDriver?.location?.latitude ?? 35.685175,
        currentDriver?.location?.longitude ?? 139.7528,
      ],
      12,
    );

    /**
     * 地理院タイルの種類
     * @type {"std" | "pale" | "blank" | "seamlessphoto"}
     * @see https://maps.gsi.go.jp/development/ichiran.html
     */
    const mapType = "pale";

    // 地理院タイルを地図に追加
    L.tileLayer(
      `https://cyberjapandata.gsi.go.jp/xyz/${mapType}/{z}/{x}/{y}.png`,
      {
        minZoom: 5,
        maxZoom: 18,
        attribution: `<a href="https://maps.gsi.go.jp/development/ichiran.html" target="_blank" rel="noreferrer">国土地理院</a>`,
      },
    ).addTo(map);
    map.attributionControl.setPrefix(false);

    // 配送センターのマーカーを描画
    try {
      for (const centerId of centerIdList) {
        const center = centersMap.get(centerId);
        L.marker([center.latitude, center.longitude], {
          icon: depotIcon,
        })
          .bindPopup(escape(center.name) + "<br />")
          .addTo(map)
          .on("click", async () => {
            currentDriverIndex = null;
          });
      }
    } catch (error) {
      console.warn(error); // use non-logger explicitly
    }

    createDriversMarkerLayer();

    createDeliveryRecordsLayerMap();

    selectConditions === driverConditionType.COURIER
      ? courierMarkerGroup.addTo(map)
      : truckerMarkerGroup.addTo(map);
  });

  /**
   * 宅配ドライバー、幹線輸送ドライバーの各マーカーのレイヤーを作成
   */
  function createDriversMarkerLayer() {
    courierMarkerGroup = L.layerGroup();
    truckerMarkerGroup = L.layerGroup();

    // 宅配ドライバーマーカーのレイヤーを作成
    for (const driver of courierResults ?? []) {
      if (driver.location) {
        const marker = L.marker(
          [driver.location.latitude, driver.location.longitude],
          { icon: new DriverIcon({ number: String(driver.index) }) },
        )
          .bindPopup(createDriverPopupContent(driver, false))
          .on("click", async () => {
            if (currentDriverIndex === driver.index) {
              currentDriverIndex = null;
            } else {
              currentDriverIndex = driver.index;

              await tick();
              const element = document.getElementsByClassName("selectedRow");
              if (element.length > 0) {
                element[0].scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }
            }
          });
        courierMarkerMap.set(driver.index, marker);
        courierMarkerGroup.addLayer(marker);
      }
    }

    // 幹線輸送ドライバーマーカーのレイヤーを作成
    for (const driver of truckerResults ?? []) {
      if (driver.location) {
        const marker = L.marker(
          [driver.location.latitude, driver.location.longitude],
          { icon: new DriverIcon({ number: String(driver.index) }) },
        )
          .bindPopup(
            `
            <div class="popup">
              <table class="popupTable">
                <caption>🚛 ${driver.userDisplayName}</caption>
                <thead>
                  <tr>
                    <th>配送先センター(荷物個数)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>${getCenterNameAndPackageNumString(centersMap, driver.transportDestivationIdAndPackageNumList)}</td>
                  </tr>
                </tbody>
              </table>
            </div>`,
          )
          .on("click", async () => {
            if (currentDriverIndex === driver.index) {
              currentDriverIndex = null;
            } else {
              currentDriverIndex = driver.index;

              await tick();
              const element = document.getElementsByClassName("selectedRow");
              if (element.length > 0) {
                element[0].scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }
            }
          });
        truckerMarkerMap.set(driver.index, marker);
        truckerMarkerGroup.addLayer(marker);
      }
    }
  }

  /**
   * ドライバーマーカーのポップアップコンテンツを作成
   * @param {import("~/libs/commonTypes").DriverActivity} driver
   * @param {boolean} deliveryRecordsMode
   * @returns {string}
   */
  function createDriverPopupContent(driver, deliveryRecordsMode) {
    return `
            <div class="popup">
              <table class="popupTable">
                <caption>🚚 ${driver.userDisplayName}</caption>
                <thead>
                  <tr>
                    <th>未配達</th>
                    <th>配達済</th>
                    <th>配達不可</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>${driver.deliveryRecordsCount.undelivered.toLocaleString()}</td>
                    <td>${driver.deliveryRecordsCount.delivered.toLocaleString()}</td>
                    <td>${driver.deliveryRecordsCount.undeliverable.toLocaleString()}</td>
                  </tr>
                </tbody>
              </table>
              <div class="timeArea">
                ${
                  driver.firstOutForDeliveryAt
                    ? `<p>初回持出：${formatStringDate(
                        driver.firstOutForDeliveryAt,
                        "MM/dd(E) H:mm",
                        {
                          locale: localeJa,
                        },
                      )}</p>`
                    : ""
                }
                ${
                  driver.lastDeliveredAt
                    ? `<p>配達完了：${formatStringDate(
                        driver.lastDeliveredAt,
                        "MM/dd(E) H:mm",
                        {
                          locale: localeJa,
                        },
                      )}</p>`
                    : ""
                }
                ${
                  driver.lastTakebackAt
                    ? `<p>持戻完了：${formatStringDate(
                        driver.lastTakebackAt,
                        "MM/dd(E) H:mm",
                        {
                          locale: localeJa,
                        },
                      )}</p>`
                    : ""
                }
              </div>
              <button class="detailButton driver${driver.index}">${deliveryRecordsMode ? "一覧モードに戻る" : "配達実績モードに切替え"}</button>
            </div>`;
  }

  /**
   * 各宅配ドライバーの配送記録のレイヤーを保持したマップを作成
   */
  async function createDeliveryRecordsLayerMap() {
    for (const driver of courierResults ?? []) {
      let deliveryRecordsMarkerGroup = L.layerGroup();
      let markedTrackingNumberList = [];
      let packageNumber = 1;

      if (driver.results?.deliveryRecords?.length > 0) {
        // 1. 配送ルートの線を描画
        let latlngs = [];
        for (const record of driver.results.deliveryRecords) {
          if (record.actualLocation) {
            latlngs.push([
              record.actualLocation.latitude,
              record.actualLocation.longitude,
            ]);
          } else if (record.location) {
            latlngs.push([record.location.latitude, record.location.longitude]);
          }
        }
        const polyLine = L.polyline(latlngs, { color: "#1C178C" });
        deliveryRecordsMarkerGroup.addLayer(polyLine);

        // 2. 配送ルートの荷物を描画
        for (const record of driver.results.deliveryRecords) {
          if (
            record.location &&
            (record.recordType === DeliveryRecordTypes.DELIVERED ||
              record.recordType === DeliveryRecordTypes.UNDELIVERABLE ||
              record.recordType === DeliveryRecordTypes.UNDELIVERABLE_NO_MOVE)
          ) {
            const marker = createRecordedPackageMarker(record, packageNumber);
            packageNumber++;
            deliveryRecordsMarkerGroup.addLayer(marker);
            markedTrackingNumberList.push(record.trackingNumber);
          }
        }

        // 3. ルートに含まれない未配達荷物を描画
        for (const record of driver.results.undeliveredList ?? []) {
          if (
            !markedTrackingNumberList.includes(record.trackingNumber) &&
            record.location
          ) {
            const marker = createUnRecordedPackageMarker(
              record,
              PackageTypes.UNDELIVERED,
              packageNumber,
            );
            packageNumber++;
            deliveryRecordsMarkerGroup.addLayer(marker);
          }
        }
      }

      // 4. ドライバーの現在地を描画
      if (driver.location) {
        const marker = L.marker(
          [driver.location.latitude, driver.location.longitude],
          { icon: new DriverIcon() },
        )
          .bindPopup(createDriverPopupContent(driver, true), {
            autoClose: false,
          })
          .on("click", async () => {
            if (currentDriverIndex === driver.index) {
              currentDriverIndex = null;
            } else {
              currentDriverIndex = driver.index;

              await tick();
              const element = document.getElementsByClassName("selectedRow");
              if (element.length > 0) {
                element[0].scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }
            }
          });
        deliveryRecordsMarkerGroup.addLayer(marker);
        courierMarkerMapForRecordsMode.set(driver.index, marker);
      }

      deliveryRecordsLayerMap.set(driver.index, deliveryRecordsMarkerGroup);
    }
  }

  /**
   * 配送記録済み荷物のマーカーを作成
   * @param {import("~/libs/backendApi").DeliveryRecord} record
   * @param {number} packageNumber
   * @returns {L.Marker}
   */
  function createRecordedPackageMarker(record, packageNumber) {
    return L.marker([record.location?.latitude, record.location?.longitude], {
      icon:
        record.recordType === DeliveryRecordTypes.DELIVERED
          ? new DeliveredIcon({ number: String(packageNumber) })
          : new UndeliverableIcon({
              number: String(packageNumber),
            }),
    })
      .bindPopup(
        `
      <div class="popup">
        <p class="trackingNumber"><b>📦 ${formatTrackingNumber(record.trackingNumber)}</b></p>
        <p class="status">${record.recordType === DeliveryRecordTypes.DELIVERED ? "配達済" : "配達不可"}
          ${Number.isInteger(record.extraEvent) ? "(" + $_(`pages.Search.extraEventTypeLabel.${record.extraEvent}`) + ")" : ""}</p>
        <p class="address">${record.address}</p>
        <button class="detailButton ${record.trackingNumber}">詳細情報</button>
      </div>`,
      )
      .on("click", async () => {
        await tick();
        document
          .getElementsByClassName(record.trackingNumber)[0]
          .addEventListener("click", () => {
            window.open(`/?t=${record.trackingNumber}`);
          });
      });
  }

  /**
   * 配送記録未登録の荷物のマーカーを作成
   * @param {import("~/libs/backendApi").PackageInfo} record
   * @param {PackageTypes} packageType
   * @param {number} packageNumber
   * @returns {L.Marker}
   */
  function createUnRecordedPackageMarker(record, packageType, packageNumber) {
    return L.marker([record.location?.latitude, record.location?.longitude], {
      icon:
        packageType === PackageTypes.UNDELIVERED
          ? new LocationIcon({ number: String(packageNumber) })
          : packageType === PackageTypes.DELIVERED
            ? new DeliveredIcon({ number: String(packageNumber) })
            : new UndeliverableIcon({
                number: String(packageNumber),
              }),
    })

      .bindPopup(
        `
    <div class="popup">
      <p class="trackingNumber"><b>📦 ${formatTrackingNumber(record.trackingNumber)}</b></p>
      <p class="status">${
        packageType === PackageTypes.UNDELIVERED
          ? "未配達"
          : packageType === PackageTypes.DELIVERED
            ? "配達済"
            : "配達不可"
      }
      <p class="address">${record.address}</p>
      <button class="detailButton ${record.trackingNumber}">詳細情報</button>
    </div>`,
      )
      .on("click", async () => {
        await tick();
        document
          .getElementsByClassName(record.trackingNumber)[0]
          .addEventListener("click", () => {
            window.open(`/?t=${record.trackingNumber}`);
          });
      });
  }

  /**
   * ドライバー番号を指定して、ドライバーの配送記録表示モードに切り替える
   * @param {number} driverIndex
   */
  async function changeForDeliveryRecordsMode(driverIndex) {
    courierMarkerGroup.removeFrom(map);
    deliveryRecordsLayerMap.get(Number(driverIndex)).addTo(map);
    courierMarkerMapForRecordsMode.get(Number(driverIndex)).addTo(map);
    courierMarkerMapForRecordsMode.get(Number(driverIndex)).openPopup();
    // ポップアップが開くまで待機
    await sleep(300);
    document
      .getElementsByClassName(`driver${driverIndex}`)[0]
      .addEventListener("click", () => {
        moveToCurrentDriver(driverIndex);
      });
    deliveryRecordsMode = true;
  }

  /**
   * ドライバーのレイヤーを切り替える
   * @param {import("~/libs/constants").driverConditionType} selectConditions
   */
  function changeLayer(selectConditions) {
    if (!map) return;

    if (deliveryRecordsMode) {
      // 配送記録表示モードの場合、配送記録レイヤーを削除
      deliveryRecordsLayerMap.get(currentDriver.index)?.removeFrom(map);
      deliveryRecordsMode = false;
    }

    if (selectConditions === driverConditionType.COURIER) {
      truckerMarkerGroup.removeFrom(map);
      courierMarkerGroup.addTo(map);
    } else {
      courierMarkerGroup.removeFrom(map);
      truckerMarkerGroup.addTo(map);
    }
  }

  /**
   * 選択中のドライバーを中心に地図を移動し、ポップアップを開く
   * @param {number} driverIndex
   */
  async function moveToCurrentDriver(driverIndex) {
    if (!driverIndex) return;
    if (!map) return;

    if (deliveryRecordsMode) {
      // 配送記録表示モードの場合、配送記録レイヤーを削除
      deliveryRecordsLayerMap.get(currentDriver.index)?.removeFrom(map);
      deliveryRecordsMode = false;
      courierMarkerGroup.addTo(map);
    }

    deliveryRecordsLayerMap.get(currentDriver.index)?.removeFrom(map);

    const activeDriverList =
      selectConditions === driverConditionType.COURIER
        ? courierResults
        : truckerResults;
    const activeDriverMarkerMap =
      selectConditions === driverConditionType.COURIER
        ? courierMarkerMap
        : truckerMarkerMap;

    currentDriver = activeDriverList.find((item) => item.index == driverIndex);

    if (!currentDriver) return;
    const driverLocation = currentDriver.location;
    if (!driverLocation) return;
    activeDriverMarkerMap.get(Number(driverIndex)).openPopup();
    map.setView([driverLocation.latitude, driverLocation.longitude]);
    if (selectConditions === driverConditionType.COURIER) {
      // ポップアップが開くまで待機
      await sleep(300);
      // ポップアップ内のボタンに処理を追加
      document
        .getElementsByClassName(`driver${driverIndex}`)[0]
        .addEventListener("click", () => {
          changeForDeliveryRecordsMode(driverIndex);
        });
    }
  }
</script>

<div id="routeMap">
  {#if deliveryRecordsMode && currentDriver?.results?.totalDistance}
    <div class="additionalInfo">
      <p class="totalDistance">
        総移動距離(概算)：{currentDriver?.results?.totalDistance
          ? (currentDriver.results.totalDistance / 1000).toFixed(2)
          : 0} km
      </p>
      {#if currentDriver?.results?.updatedAt}
        <p class="updatedAt">
          最終更新：{formatStringDate(
            currentDriver.results.updatedAt,
            "MM/dd(E) H:mm",
            {
              locale: localeJa,
            },
          )}
        </p>
      {/if}
    </div>
  {/if}
</div>

<style lang="scss">
  #routeMap {
    z-index: 1;
    width: 100%;
    height: 100%;

    :global(.leaflet-location-icon) {
      background: transparent;
      border: none;
    }
    :global(.leaflet-location-icon .number) {
      position: relative;
      top: -41px;
      width: 26px;
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      color: #fff;
    }
    :global(.leaflet-driver-icon .number) {
      position: relative;
      top: -32px;
      width: 35px;
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      color: #fff;
    }

    :global(.leaflet-popup-content) {
      margin: 13px 12px 13px 15px;
    }

    :global(.material-icons) {
      vertical-align: bottom;
    }

    :global(.popup) {
      display: column;
      text-align: center;
      :global(.popupTable) {
        width: 165px;
        height: 35px;
        font-size: 12px;
        text-align: center;
        margin: -10px 0 0;
        border: solid 1px black;

        :global(caption) {
          caption-side: top;
          max-width: 165px;
          font-weight: bold;
          padding: 5px;
        }

        :global(thead) {
          :global(tr) {
            font-size: 11px;
            background-color: rgb(228 237 255);
          }
          :global(th) {
            width: 55px;
            border: solid 1px black;
          }
        }

        :global(tbody) {
          :global(td) {
            border: solid 1px black;
            vertical-align: middle;
          }
        }
      }

      :global(.timeArea) {
        font-size: 12px;
        margin-top: 3px;

        :global(p) {
          margin: 0;
        }
      }

      :global(.detailButton) {
        margin: 4px 0 -4px;
        font-size: 12px;
        background-color: #064491cb;
        border: none;
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        cursor: pointer;
      }

      :global(.detailButton:hover) {
        background-color: #064591ab;
      }

      :global(.trackingNumber) {
        margin: 0;
      }

      :global(.status) {
        margin: 0;
      }

      :global(.address) {
        margin: 0;
      }
    }

    .additionalInfo {
      text-align: center;
      font-size: 12px;
      position: absolute;
      bottom: 22px;
      right: 5px;
      z-index: 1000;
      background-color: rgba(255, 255, 255, 0.774);
      box-shadow:
        0px 4px 6px rgba(0, 0, 0, 0.2),
        0px 1px 3px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      padding: 5px 10px;
      .totalDistance {
        line-height: 1.5;
      }
      .updatedAt {
        font-size: 11px;
        line-height: 1.2;
        color: #666;
        text-align: right;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .additionalInfo {
      padding: 5px 7px !important;

      .totalDistance {
        font-size: 11px;
      }
      .updatedAt {
        font-size: 9px !important;
        line-height: 1.1;
      }
    }
  }
</style>
